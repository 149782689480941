.commands-wrapper {
  box-sizing: border-box;
  width: 85vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #333333AA;
  border-radius: 12px;
  padding: 20px 80px;
}

.command {
  display: flex;
  flex-direction: column;
  margin: 4px 0;
}

code {
  background-color: #111111;
  border-radius: 12px;
  color: #b4b4b4;
  margin-top: 5px;
}