.editions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.edition {
  width: 600px;
  height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333333AA;
  border-radius: 12px;
  margin: 0 20px;
}

.parity {
  width: 1880px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333333AA;
  border-radius: 12px;
}

.edition-logo {
  height: 30vh;
  border-radius: 12px;
}