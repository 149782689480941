.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content > div {
  margin: 15px 0;
}

.intro {
  width: 85vw;
  height: 45vh;
  background-color: #333333aa;
  border-radius: 12px;
}

.cards {
  width: 55vw;
  height: 30vh;
  background-color: #333333aa;
  border-radius: 12px;
}

.cards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card-icon {
  font-size: 25vh;
  margin: 0 10vh;
}

.card-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.intro {
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  margin-top: 30px;
  height: 80px;
}

.explore {
  display: flex;
  flex-direction: column;
  height: 30px;
  margin-top: 50px;
}

.explore span {
  margin: 5px 0;
  font-weight: bold;
  font-variant: small-caps;
  font-size: 1.2em;
}

.intro-link {
  color: #dddddd;
  border: 1px solid #2b5ecc;
  width: 15vh;
  height: 4.5vh;
  margin: 20px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.1s;
  box-shadow: 0px 6px 0px #0d37927c;
}

.intro-link:hover {
  box-shadow: 0px 6px 0px #0d3792;
}

.intro-link * {
  margin: 0 5px;
}

.intro-link:active {
  box-shadow: 0px 2px 0px #0d3792;
  position: relative;
  top: 4px;
}

.btn-icon {
  font-size: 2.2vh;
}

.primary:hover,
.primary:active {
  background-color: #1451d6;
  transition: 0.075s background-color ease-in-out;
}

.primary {
  background-color: #1452d67c;
  transition: 0.075s background-color ease-in-out;
}

.secondary:hover,
.secondary:active {
  background-color: #464646;
  transition: 0.075s background-color ease-in-out;
}

.secondary {
  background-color: #4646467c;
  transition: 0.075s background-color ease-in-out;
}

.brand-img {
  margin-top: 40px;
  width: auto;
  height: 400px;
  border-radius: 15px;
}

.explore {
  cursor: pointer;
}
