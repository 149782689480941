body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #000000;
}

.background {
  z-index: -1000;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: linear-gradient(90deg, #000000 calc(75px - 3px), transparent 1%)
      center,
    linear-gradient(#000000 calc(75px - 3px), transparent 1%) center, #ffffff;
  background-size: 75px 75px;
  animation: dots 86400s linear infinite;
}

@keyframes dots {
  0% {
    background-position: -20000% 20000%;
  }
  100% {
    background-position: 20000% -20000%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #666666;
  transition: 0.3s background-color ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #444444;
  transition: 0.3s background-color ease-in-out;
}
